<template>
    <div>
        <form class="ink-form">
            <div class="control-group column-group horizontal-gutters">
                <div class="control all-40">
                    <select v-if="domainOptions.length > 1" :disabled="!$parent.canChangeDomain" id="select" v-model="$parent.punyData.domain" class="align-right" required>
                        <option disabled selected value="null">Escolher domínio</option>
                        <option v-for="(option, idx) in domainOptions" :value="option.value" :key="idx">
                            {{ option.text }}
                        </option>
                    </select>
                    <select v-if="domainOptions.length == 1" :disabled="!$parent.canChangeDomain" id="select" v-model="$parent.punyData.domain" class="align-right" required>
                        <option selected :key="domainOptions[0].value" :value="domainOptions[0].value">{{ domainOptions[0].value }}</option>
                    </select>
                </div>

                <div class="control all-30">
                    <input
                        id="slug"
                        name="slug"
                        v-model="$parent.punyData.slug"
                        type="text"
                        class="form-control"
                        required
                        :disabled="$parent.canEdit"
                        @focus="$parent.clearAlert()"
                    />
                </div>

                <div class="all-30 button-group" v-if="$parent.canDelete">
                    <button type="button" class="ink-button" @click="$parent.copyLink">
                        <span class="fa fa-copy"></span> Copiar
                    </button>
                    <p style="text-align: end; line-height: 45px; margin-bottom: 0px;">{{ $parent.punyData.accessCount }} acessos</p>
                </div >
            </div >

            <div class="control-group column-group horizontal-gutters">
                <label for="longUrl" class="all-40 align-right"
                    >URL Longo</label
                >
                <div class="control all-60">
                    <input
                        id="longUrl"
                        name="longUrl"
                        v-model="$parent.punyData.longUrl"
                        type="text"
                        class="form-control"
                        required
                        @focus="$parent.clearAlert()"
                    />
                </div>
            </div>
            <div class="control-group column-group horizontal-gutters">
                <label for="expiresAt" class="all-40 align-right"
                    >Data de Expiração</label
                >
                <div class="control all-30">
                    <input
                        id="expiresAt"
                        name="expiresAt"
                        v-model="$parent.punyData.expiresAt"
                        type="date"
                        class="form-control"
                        @focus="$parent.clearAlert()"
                    />
                </div>
            </div>
        </form >
    </div >
</template >

<script>
export default {
    name: 'PunyForm',
    data: () => ({
        domainOptions: []
    }),
    mounted: function () {
        this.setData();
    },
    methods: {
        setData: function () {
            const domains = process.env.VUE_APP_BO_DOMAINS;
            domains.split('|').forEach((d) => {
                this.domainOptions.push({ text: d, value: d });
            });

            if (this.domainOptions.length === 1) {
                this.$parent.punyData.domain = this.domainOptions[0].value;
            }
        }
    }
};
</script>

<style scoped></style>
