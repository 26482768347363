<template>
    <div class="vertical-padding">
        <UsersForm />

        <div class="column-group">
            <div class="all-50 push-center">
                <div class="button-toolbar">
                    <div class="button-group">
                        <button class="ink-button" @click="addUser">
                            <span class="fa fa-edit"></span> Adicionar utilizador
                        </button>
                        <button class="ink-button" @click="saveUsers">
                            <span class="fa fa-save"></span> Guardar
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div
            class="ink-alert basic"
            :class="alertType"
            v-if="alertMsg"
            role="alert"
        >
            <p>{{ alertMsg }}</p>
        </div>
    </div>
</template>

<script>
import UsersForm from './../components/UsersForm.vue';

export default {
    name: 'users-view',
    components: {
        UsersForm
    },
    data: () => ({
        userInput: '',
        users: [],
        alertMsg: '',
        alertType: ''
    }),
    mounted () {
        this.populateUsers();
    },
    methods: {
        clearAlert: function () {
            this.setAlert('', '');
        },
        setAlert: function (type, msg) {
            this.alertMsg = msg;
            this.alertType = type;
        },
        populateUsers () {
            this.axios.get('/get_users')
                .then((response) => {
                    this.users = response.data.users.map(user => user.email).sort();
                })
                .catch(({ response }) => {
                    this.setAlert(
                        'error',
                        `Erro ao ler users - ${response.data.statusText}`
                    );
                    return null;
                });
        },
        addUser () {
            this.clearAlert();
            if (this.userInput.trim() !== '') {
                this.userInput.trim().split(';').forEach(u => {
                    const user = u.trim();
                    const userRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                    if (userRegex.test(user)) {
                        this.users.push(user);
                        this.userInput = '';
                    } else {
                        this.setAlert('error', 'Insere um email válido');
                    }
                });
            }
        },
        removeUser (index) {
            this.users.splice(index, 1);
        },
        saveUsers () {
            const requestObj = {
                users: this.users
            };

            this.axios.post('/add_users', requestObj)
                .then((response) => {
                    const result = response.data;
                    if (result) {
                        this.setAlert('success', 'Utilizadores alterados com sucesso.');
                    }
                })
                .catch(({ response }) => {
                    this.setAlert(
                        'error',
                        `Erro ao adicionar Utilizadores - ${response.data.statusText}`
                    );
                    return null;
                });
        }
    }
};
</script>

<style></style>
