<template>
    <div>
        <form class="ink-form">
            <div class="control-group column-group horizontal-gutters">
                <div class="control all-40">
                    <select v-if="domainOptions.length > 1" id="select" v-model="$parent.punyData.domain" class="align-right" required>
                        <option disabled selected value="null">Escolher domínio</option>
                        <option v-for="(option, idx) in domainOptions" :value="option.value" :key="idx">
                            {{ option.text }}
                        </option>
                    </select>
                    <select v-if="domainOptions.length == 1" id="select" v-model="$parent.punyData.domain" class="align-right" required>
                        <option selected :key="domainOptions[0].value" :value="domainOptions[0].value">{{ domainOptions[0].value }}</option>
                    </select>
                </div>

                <div class="all-30 button-group">
                    <button type="button" class="ink-button" @click="$parent.search">
                        <span class="fa fa-copy"></span> Pesquisar</button>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
export default {
    name: 'SearchForm',
    data: () => ({
        domainOptions: []
    }),
    mounted: function () {
        this.setData();
    },
    methods: {
        setData: function () {
            const domains = process.env.VUE_APP_BO_DOMAINS;
            domains.split('|').forEach((d) => {
                this.domainOptions.push({ text: d, value: d });
            });

            if (this.domainOptions.length === 1) {
                this.$parent.punyData.domain = this.domainOptions[0].value;
            }
        }
    }
};
</script>

<style scoped></style>
