<template>
    <div class="vertical-padding">
        <SearchForm />

        <div>
            <table id="searchResultTable">
            <thead>
                <tr>
                <th @click="sortResults('shortUrl')">Puny</th>
                <th @click="sortResults('url')">URL</th>
                <th @click="sortResults('creationDate')">Data Criação</th>
                <th @click="sortResults('expirationDate')">Data Expiração</th>
                <th @click="sortResults('updatedBy')">Atualizado por</th>
                <th @click="sortResults('accessCount')">Acessos</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(item, index) in searchResult" :key="index">
                <td>
                    <a :href="item.shortUrl" target="_blank">{{ item.shortUrl }}</a>
                </td>
                <td>
                    <a :href="item.url" target="_blank">{{ item.url }}</a>
                </td>
                <td>{{ item.creationDate }}</td>
                <td>{{ item.expirationDate }}</td>
                <td>{{ item.updatedBy }}</td>
                <td>{{ item.accessCount }}</td>
                </tr>
            </tbody>
            </table>
        </div>
  </div>
</template>

<script>
import SearchForm from './../components/SearchForm.vue';

export default {
    name: 'search-view',
    components: {
        SearchForm
    },
    data: () => ({
        baseUrl: process.env.VUE_APP_API_BASE_URL,
        punyData: {
            domain: null
        },
        searchResult: [],
        sortKey: '',
        sortOrders: {
            name: 1,
            age: 1,
            email: 1,
            updatedBy: 1
        }
    }),

    methods: {
        search: function () {
            if (this.punyData.domain) {
                this.axios.get(`/search_by_domain/${encodeURIComponent(this.punyData.domain)}`)
                    .then((response) => {
                        const result = response.data;
                        if (result) {
                            this.searchResult = result.map((el) => {
                                return {
                                    domain: el.domain,
                                    urlCode: el.urlCode,
                                    shortUrl: `https://${el.domain}/${el.urlCode}`,
                                    url: el.url,
                                    creationDate: el.createdAt,
                                    expirationDate: el.expiresAt,
                                    updatedBy: el.updatedBy,
                                    accessCount: el.accessCount || 0
                                };
                            });
                        }
                    })
                    .catch(() => {});
            }
        },
        sortResults: function (key) {
            if (!this.sortOrders[key]) {
                this.sortOrders[key] = 1;
            }

            if (this.sortKey === key) {
                this.sortOrders[key] *= -1;
            } else {
                this.sortKey = key;
            }

            const order = this.sortOrders[this.sortKey];
            this.searchResult = this.searchResult.slice().sort((a, b) => {
                if (a[this.sortKey] > b[this.sortKey]) return 1 * order;
                if (a[this.sortKey] < b[this.sortKey]) return -1 * order;
                return 0;
            });
        }
    }
};
</script>

<style>
table{
        border-collapse: collapse;
            width: 80%;
            margin: 20px auto;
            border: 1px solid black;
}

            th, td {
            border: 1px solid black;
            }
        thead tr th {
            cursor: pointer;
        }

        th, td {
            padding: 10px;
            text-align: left;
        }

        th {
            background-color: #333;
            color: white;
        }

        tr:nth-child(even) {
            background-color: #f2f2f2;
        }

        tr:nth-child(odd) {
            background-color: #fff;
        }
</style>
