<template>
    <div class="vertical-padding">
        <div class="column-group">
            <h1>URL Shortener</h1>
            <h2>BackOffice Administrativo</h2>
            <div class="all-10 push-center">
                <div class="button-toolbar">
                    <div class="button-group">
                        <a
                            class="ink-button"
                            :href="loginUrl"
                        >
                            <span class="fa fa-key"></span> Entrar
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'home-view',
    data: () => ({
        loginUrl: `${process.env.VUE_APP_BO_BASE_URL}/admin`
    })
};
</script>

<style></style>
