import { createRouter, createWebHistory } from 'vue-router'
import * as Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'

import HomeView from './views/HomeView.vue'
import AdminView from './views/AdminView.vue'
import TableView from './views/TableView.vue'
import UsersView from './views/UsersView.vue'
import App from './App.vue'

const routes = [
    {
        path: '/',
        name: 'home',
        component: HomeView
    },
    {
        path: '/admin',
        name: 'admin',
        component: AdminView
    },
    {
        path: '/users',
        name: 'users',
        component: UsersView
    },
    {
        path: '/admin/list',
        name: 'list',
        component: TableView
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

const app = Vue.createApp(App);
app.use(router);
app.use(VueAxios, axios)
app.mount('#app')
