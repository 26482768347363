<template>
    <div class="vertical-padding">
        <PunyForm />

        <div class="column-group">
            <div class="all-50 push-center">
                <div class="button-toolbar">
                    <div class="button-group">
                        <button
                            class="ink-button"
                            :disabled="!canSearch || !punyData.slug"
                            @click="search"
                        >
                            <span class="fa fa-search"></span> Pesquisar
                        </button>
                        <button
                            class="ink-button"
                            :disabled="!canCreate || !punyData.longUrl"
                            @click="create"
                        >
                            <span class="fa fa-save"></span> Criar
                        </button>
                        <button
                            class="ink-button"
                            :disabled="!canEdit"
                            @click="edit"
                        >
                            <span class="fa fa-edit"></span> Atualizar
                        </button>
                        <button
                            class="ink-button"
                            :disabled="!canDelete"
                            @click="deletePuny"
                        >
                            <span class="fa fa-trash"></span> Apagar
                        </button>
                    </div>
                    <div class="button-group">
                        <button
                            class="ink-button"
                            v-if="!canSearch"
                            @click="clear"
                        >
                            <span class="fa fa-undo"></span> Voltar
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div
            class="ink-alert basic"
            :class="alertType"
            v-if="alertMsg"
            role="alert"
        >
            <p>{{ alertMsg }}</p>
        </div>
    </div>
</template>

<script>
import PunyForm from './../components/PunyForm.vue';
import validUrl from 'valid-url';

export default {
    name: 'admin-view',
    components: {
        PunyForm
    },
    data: () => ({
        baseUrl: process.env.VUE_APP_API_BASE_URL,
        alertMsg: '',
        alertType: '',
        punyData: {
            domain: null,
            slug: null,
            longUrl: null,
            expiresAt: null
        },
        canChangeDomain: true,
        canCreate: true,
        canEdit: false,
        canSearch: true,
        canDelete: false
    }),

    methods: {
        clearAlert: function () {
            this.setAlert('', '');
        },
        setAlert: function (type, msg) {
            this.alertMsg = msg;
            this.alertType = type;
        },
        create: function () {
            this.clearAlert();

            if (!this.punyData.domain) {
                this.setAlert('error', 'Necessário Domínio');
                return;
            }

            if (!this.punyData.longUrl) {
                this.setAlert('error', 'Necessário URL Longo');
                return;
            }
            if (!validUrl.isUri(this.punyData.longUrl)) {
                this.setAlert('error', 'URL Longo inválido');
                return;
            }

            if (!(/^\w+$/.test(this.punyData.slug))) {
                this.setAlert('error', 'Puny inválido, apenas utilizar letras, números e "_"');
                return;
            }

            const requestObj = {
                domain: this.punyData.domain,
                url: this.punyData.longUrl
            };
            this.punyData.slug && (requestObj.slug = this.punyData.slug);

            if (this.punyData.expiresAt) {
                if (new Date(this.punyData.expiresAt).getTime() - new Date() < 0) {
                    this.setAlert('error', 'A Data de Expiração necessita de ser uma data futura');
                    return;
                }
                requestObj.expiresAt = this.punyData.expiresAt
            }

            this.axios.post('/create', requestObj)
                .then((response) => {
                    const result = response.data;
                    if (result) {
                        this.punyData = {
                            domain: 'https://' + result.domain,
                            slug: result.puny.replace(this.punyData.domain + '/', ''),
                            longUrl: result.url,
                            accessCount: result.accessCount,
                            puny: result.puny
                        };
                        if (result.expiresAt) {
                            this.punyData.expiresAt = new Date(result.expiresAt)
                                .toISOString()
                                .substr(0, 10);
                        }
                        this.canCreate = false;
                        this.canChangeDomain = false;
                        this.canSearch = false;
                        this.canEdit = true;
                        this.canDelete = true;
                        this.setAlert('success', 'Criado com sucesso');
                    }
                })
                .catch(({ response }) => {
                    this.setAlert(
                        'error',
                        `Erro ao criar - ${response.data.statusText}`
                    );
                    this.canCreate = true;
                    this.canSearch = true;
                    this.canChangeDomain = true;
                    return null;
                });
        },
        edit: function () {
            this.clearAlert();

            if (!this.punyData.domain) {
                this.setAlert('error', 'Necessário Domínio');
                return;
            }

            if (!this.punyData.longUrl) {
                this.setAlert('error', 'Necessário URL Longo');
                return;
            }
            if (!validUrl.isUri(this.punyData.longUrl)) {
                this.setAlert('error', 'URL Longo inválido');
                return;
            }
            if (!this.punyData.slug) {
                this.setAlert('error', 'Necessário Slug');
                return;
            }
            if (!(/^\w+$/.test(this.punyData.slug))) {
                this.setAlert('error', 'Puny inválido, apenas utilizar letras, números e "_"');
                return;
            }

            const requestObj = {
                domain: this.punyData.domain,
                slug: this.punyData.slug,
                url: this.punyData.longUrl
            };

            if (this.punyData.expiresAt) {
                if (new Date(this.punyData.expiresAt).getTime() - new Date() < 0) {
                    this.setAlert('error', 'A Data de Expiração necessita de ser uma data futura');
                    return;
                }
                requestObj.expiresAt = this.punyData.expiresAt
            }

            this.axios.put('/update', requestObj)
                .then((response) => {
                    const result = response.data;
                    if (result) {
                        this.punyData = {
                            domain: 'https://' + result.domain,
                            slug: result.puny.replace(result.domain + '/', ''),
                            longUrl: result.url,
                            puny: result.puny,
                            accessCount: result.accessCount
                        };
                        if (result.expiresAt) {
                            this.punyData.expiresAt = new Date(result.expiresAt)
                                .toISOString()
                                .substr(0, 10);
                        }
                        this.canCreate = false;
                        this.canSearch = false;
                        this.canEdit = true;
                        this.canDelete = true;
                        this.setAlert('success', 'Atualizado com sucesso');
                    }
                })
                .catch(({ response }) => {
                    this.setAlert(
                        'error',
                        `Erro ao atualizar - ${response.data.statusText}`
                    );
                    return null;
                });
        },
        search: function () {
            this.clearAlert();

            if (this.punyData.slug && this.punyData.domain) {
                if (!(/^\w+$/.test(this.punyData.slug))) {
                    this.setAlert('error', 'Puny inválido, apenas utilizar letras, números e "_"');
                    return;
                }

                this.axios.get(`/search/${encodeURIComponent(this.punyData.domain + '/' + this.punyData.slug)}`)
                    .then((response) => {
                        const result = response.data;
                        if (result) {
                            this.punyData = {
                                domain: 'https://' + result.domain,
                                slug: result.puny.replace(result.domain + '/', ''),
                                longUrl: result.url,
                                accessCount: result.accessCount,
                                puny: result.puny
                            };
                            if (result.expiresAt) {
                                this.punyData.expiresAt = new Date(
                                    result.expiresAt
                                )
                                    .toISOString()
                                    .substr(0, 10);
                            }
                            this.canChangeDomain = false;
                            this.canCreate = false;
                            this.canEdit = true;
                            this.canDelete = true;
                            this.canSearch = false;
                        }
                    })
                    .catch(() => {
                        this.setAlert('error', 'Registo não encontrado');

                        this.canCreate = true;
                        return null;
                    });
            }
        },
        deletePuny: function () {
            this.clearAlert();

            if (!this.punyData.domain) {
                this.setAlert('error', 'Necessário Domínio');
                return;
            }

            if (!this.punyData.slug) {
                this.setAlert('error', 'Necessário Slug');
                return;
            }

            if (!(/^\w+$/.test(this.punyData.slug))) {
                this.setAlert('error', 'Puny inválido, apenas utilizar letras, números e "_"');
                return;
            }

            if (!confirm('Confirma que quer apagar o registo?')) {
                return;
            }

            const requestObj = {
                domain: this.punyData.domain,
                slug: this.punyData.slug
            };
            this.axios.post('/delete', requestObj)
                .then((response) => {
                    const result = response.data;
                    if (result) {
                        this.clear();
                        this.setAlert('success', 'Apagado com sucesso');
                    }
                })
                .catch(({ response }) => {
                    this.setAlert(
                        'error',
                        `Erro ao apagar - ${response.data.statusText}`
                    );
                    return null;
                });
        },
        clear: function () {
            this.clearAlert();
            this.canChangeDomain = true;
            this.canCreate = true;
            this.canEdit = false;
            this.canSearch = true;
            this.canDelete = false;
            this.punyData = {
                domain: null,
                slug: null,
                longUrl: null,
                expiresAt: null
            };
        },
        copyLink: function () {
            const puny = `${this.punyData.domain}/${this.punyData.slug}`;
            navigator.clipboard && navigator.clipboard.writeText(puny);
        }
    }
};
</script>

<style></style>
