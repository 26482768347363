<template>
    <div>
        <form class="ink-form" @submit.prevent>
            <div class="control-group column-group horizontal-gutters">
                <label for="users" class="all-20 align-right">Novo utilizador:</label>
                <div class="control all-60">
                    <input
                        id="users"
                        name="users"
                        v-model="$parent.userInput"
                        type="text"
                        class="form-control"
                        @keyup.enter.prevent="$parent.addUser"
                    />
                </div>
            </div>
            <div class="control-group column-group horizontal-gutters">
                <label class="all-20 align-right">Utilizadores Existentes:</label>
                <div class="control all-60">
                    <ul class="no-bullets">
                        <li v-for="(email, index) in $parent.users" :key="index">
                            <input type="email" disabled v-model="$parent.users[index]" required>
                            <button type="button" @click="$parent.removeUser(index)">X</button>
                        </li>
                    </ul>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
export default {
    name: 'UsersForm'
};
</script>

<style scoped>
ul.no-bullets {
  list-style-type: none;
  padding: 0;
  margin: 0;
  max-height: 500px;
  overflow-y: scroll;
}

li input{
    width: 90%;
    margin-right: 1%;
}
</style>
